import { combineReducers } from "redux";
import { globalReducer } from "./global/global.reducer";
import { api } from "./api/api";
import { whatsappApi } from "./api/whatsapp.api";
import { wpChatReducer } from "./reducer/wp-chat.reducer";

export const rootReducer = combineReducers({
  global: globalReducer,
  wpChatReducer: wpChatReducer,
  [api.reducerPath]: api.reducer,
  [whatsappApi.reducerPath]: whatsappApi.reducer
});
