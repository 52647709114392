import { Button, Modal } from "antd";
import type { ButtonProps } from "antd";
import {
  useCancelOrderMutation,
  useCreateOrgMutation,
  useGetOrgsQuery,
  useGetPendingOrderQuery,
  useGetSummariesQuery,
  useLazyGetInternalLeadStatusQuery,
  useLazyGetMemberQuery,
  useLazyLoginOrgQuery,
  useUsersSyncQuery,
} from "../../store/api/api";
import { contactUs } from "../../utils/utils";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { newTeamName } from "../../utils/constants";
import { useDispatch } from "react-redux";
import {
  setPurchaseOrgId,
  setRenewOrgId,
  setTopUpOrgId,
  setUpgradeOrgId,
} from "../../store/global/global.reducer";
import { useState } from "react";
import { sharedDemoOrgs } from "./constants";

export const PurchaseButton = (
  props: Readonly<
    ButtonProps & {
      textClassName?: string;
    }
  >
) => {
  const { textClassName, ...buttonProps } = props;
  const dispatch = useDispatch();
  const [createOrg] = useCreateOrgMutation();
  const [cancelOrder] = useCancelOrderMutation();
  const pendingOrderQuery = useGetPendingOrderQuery();
  const refetchPendingOrder = async () => {
    try {
      if (!pendingOrderQuery.isUninitialized) {
        return await pendingOrderQuery.refetch();
      }
    } catch (e) {}
  };
  const [loginOrg] = useLazyLoginOrgQuery();
  const { data: orgs, refetch: refetchOrgs } = useGetOrgsQuery();
  const { data: summaries, refetch: refetchSummaries } = useGetSummariesQuery();
  const { data: usersSync } = useUsersSyncQuery();
  const maxOrgCount = usersSync?.user?.org_creation_limit || 0;
  const maxReached = maxOrgCount === orgs?.length;
  const hasSingleOrg = orgs?.length === 1;
  const isTrial = summaries?.every((s) => s.is_trial);
  const [loading, setLoading] = useState(false);
  const [getInternalLeadStatus] = useLazyGetInternalLeadStatusQuery();
  const [getMember] = useLazyGetMemberQuery();
  const createOrgAndOpenModal = async () => {
    const loggedInOrgId = usersSync?.org?.id || 0;
    const loggedInUserId = usersSync?.user?.id || 0;
    if (sharedDemoOrgs.includes(loggedInOrgId)) {
      const member = await getMember(loggedInUserId);
      const orgs = member?.data?.orgs || [];
      const newOrg = orgs.find((o) => {
        const isSharedOrg = sharedDemoOrgs.includes(o.id);
        const hasSuperfoneNumber = !!o.superfone_number;
        return !isSharedOrg && !hasSuperfoneNumber;
      });
      if (newOrg) {
        await loginOrg(newOrg.id);
        dispatch(setPurchaseOrgId(newOrg.id));
        Promise.all([refetchOrgs(), refetchSummaries()]);
      } else {
        const details = await getInternalLeadStatus();
        if (
          details?.data?.filled_form?.business_name ||
          details?.data?.filled_form?.email
        ) {
          let orgId = 0;

          const response = await createOrg({
            name: details?.data?.filled_form?.business_name || newTeamName,
            email: details?.data?.filled_form?.email || "",
            metadata: details?.data,
          });
          if ("data" in response) {
            orgId = response?.data?.organization?.id || 0;
          }

          if (orgId) {
            await loginOrg(orgId);
            dispatch(setPurchaseOrgId(orgId));
          }
          Promise.all([refetchOrgs(), refetchSummaries()]);
        }
      }
    } else {
      const createdOrgResponse = await createOrg({
        name: newTeamName,
      });
      if ("data" in createdOrgResponse) {
        const orgId = createdOrgResponse?.data?.organization?.id || 0;
        if (orgId) {
          await loginOrg(orgId);
          dispatch(setPurchaseOrgId(orgId));
        }
        Promise.all([refetchOrgs(), refetchSummaries()]);
      }
    }
  };

  return (
    <Button
      {...buttonProps}
      loading={loading}
      disabled={loading || (hasSingleOrg && isTrial)}
      type="primary"
      onClick={async (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        if (maxReached) {
          contactUs("I want to purchase additional superfone number");
        } else {
          setLoading(true);
          const pendingOrder = await refetchPendingOrder();
          const pendingOrderId = pendingOrder?.data?.id || 0;
          if (pendingOrderId) {
            const orgId = pendingOrder?.data?.org_id || 0;
            const orderType = pendingOrder?.data?.details?.order_type;
            await loginOrg(orgId);

            Modal.confirm({
              title: "You already have a payment in progress",
              icon: <ExclamationCircleOutlined />,
              content: "Cancel and start a new Payment?",
              cancelText: "Continue older payment",
              okText: "Start new",
              onOk: async () => {
                await cancelOrder({
                  order_id: pendingOrderId,
                  status: "cancelled",
                });
                await createOrgAndOpenModal();
              },
              cancelButtonProps: {
                onClick: () => {
                  if (orderType === "PURCHASE") {
                    dispatch(setPurchaseOrgId(orgId));
                  } else if (orderType === "TOPUP") {
                    dispatch(setTopUpOrgId(orgId));
                  } else if (orderType === "RENEW") {
                    dispatch(setRenewOrgId(orgId));
                  } else if (orderType === "UPGRADE") {
                    dispatch(setUpgradeOrgId(orgId));
                  }
                  Modal.destroyAll();
                },
              },
              closable: true,
            });
          } else {
            await createOrgAndOpenModal();
          }
          setLoading(false);
        }
      }}
    >
      <span
        className={`${
          textClassName || ""
        } max-w-full text-ellipsis whitespace-nowrap overflow-hidden`}
      >
        {maxReached ? "Contact Us to Buy a New Number" : "Buy a new Number !"}
      </span>
    </Button>
  );
};
