import Lite from "../../assets/images/lite.svg";
import Pro from "../../assets/images/pro.svg";
export enum old_plan_ids {
  biz = 18,
  lite = 19,
  pro = 20,
  wabaAdv = 12,
  standardV3 = 9,
  wabaV2 = 17,
  freeTier = 15,
  wabaBiz2 = 21,
  wabaLitev1 = 22,
  wabaCRMv1 = 16,
  crmTrial = 14,
  crmV1 = 13,
  wabaV1 = 10,
  standardCrmLegacy = 23,
  crmIntV1 = 11,
  sharedDemo = 24,
}

export enum plan_ids {
  biz = 1017,
  lite = 1015,
  pro = 1016,
  bizWabaAdv = 1019,
  proWabaAdv = 1018,
  liteWabaAdv = 1020,
}

const oldBizPlan = {
  amount: 400,
  short_name: "Phone",
  name: "Business Phone",
  icon: null,
  description: "Shareable phone number for your team",
  backgroundClass: "bg-[#FFF6CE]",
  variants: [
    {
      id: 1079,
      name: "Half Yearly",
      disabled: false,
      monthly: 343,
      text: {
        primary: "Free incoming & 3000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
    {
      id: 1078,
      name: "Yearly",
      disabled: false,
      monthly: 400,
      text: {
        primary: "Free incoming & 6000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
  ],
};

const oldLitePlan = {
  amount: 499,
  short_name: "Lite",
  name: "All-in-one",
  icon: <img className="w-[60px] h-5" alt="lite" src={Lite} />,
  description: "Affordable combo for small businesses",
  backgroundClass: "bg-[#E2FDFA]",
  variants: [
    {
      id: 1088,
      name: "Half Yearly",
      disabled: true,
      monthly: 0,
      text: {
        primary: "",
        secondary: "",
      },
    },
    {
      id: 1080,
      name: "Yearly",
      disabled: false,
      monthly: 427,
      text: {
        primary: "Free incoming & 6000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
  ],
};

const oldProPlan = {
  amount: 999,
  short_name: "Pro",
  name: "All-in-one",
  icon: <img className="w-[60px] h-5" alt="pro" src={Pro} />,
  description: "Advanced plan for growing businesses",
  backgroundClass: "bg-[#F3E6F9]",
  variants: [
    {
      id: 1083,
      name: "Half Yearly",
      disabled: false,
      monthly: 999,
      text: {
        primary: "Free incoming & 3000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
    {
      id: 1082,
      name: "Yearly",
      disabled: false,
      monthly: 856,
      text: {
        primary: "Free incoming & 6000 outgoing mins",
        secondary: "Top-up at ₹600/ per 1000 min.",
      },
    },
  ],
};

const newBizPlan = {
  amount: 400,
  short_name: "Phone",
  name: "Business Phone",
  icon: null,
  description: "Shareable phone number for your team",
  backgroundClass: "bg-[#FFF6CE]",
  variants: [
    {
      id: 1098,
      name: "Half Yearly",
      disabled: false,
      monthly: 343,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 30 min/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1097,
      name: "Yearly",
      bestDeal: true,
      disabled: false,
      monthly: 400,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "⭐ 1 hr extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const newLitePlan = {
  amount: 499,
  short_name: "Lite",
  name: "All-in-one",
  icon: <img className="w-[60px] h-5" alt="lite" src={Lite} />,
  description: "Affordable combo for small businesses",
  backgroundClass: "bg-[#E2FDFA]",
  variants: [
    {
      id: 1094,
      name: "Half Yearly",
      disabled: true,
      monthly: 0,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1093,
      name: "Yearly",
      bestDeal: true,
      disabled: false,
      monthly: 427,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

const newProPlan = {
  amount: 999,
  short_name: "Pro",
  name: "All-in-one",
  icon: <img className="w-[60px] h-5" alt="pro" src={Pro} />,
  description: "Advanced plan for growing businesses",
  backgroundClass: "bg-[#F3E6F9]",
  variants: [
    {
      id: 1096,
      name: "Half Yearly",
      disabled: false,
      monthly: 999,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 1.5 hrs/ day",
          extra: "",
        },
        incoming: "Incoming free",
      },
    },
    {
      id: 1095,
      name: "Yearly",
      bestDeal: true,
      disabled: false,
      monthly: 856,
      text: {
        rate: "Top-up at ₹15/ per hour.",
        outgoing: {
          text: "Outgoing 3 hrs/ day",
          extra: "⭐ 1.5 hrs extra",
        },
        incoming: "Incoming free",
      },
    },
  ],
};

export const planConfig = {
  [old_plan_ids["biz"]]: oldBizPlan,
  [old_plan_ids["lite"]]: oldLitePlan,
  [old_plan_ids["pro"]]: oldProPlan,
  [old_plan_ids["wabaAdv"]]: oldProPlan,
  [plan_ids["biz"]]: newBizPlan,
  [plan_ids["lite"]]: newLitePlan,
  [plan_ids["pro"]]: newProPlan,
  [plan_ids["bizWabaAdv"]]: newBizPlan,
  [plan_ids["liteWabaAdv"]]: newLitePlan,
  [plan_ids["proWabaAdv"]]: newProPlan,
};

export const getPlanLevel = (planId?: number) => {
  const isNewBiz =
    planId === plan_ids["biz"] || planId === plan_ids["bizWabaAdv"];
  const isBiz = planId === old_plan_ids["biz"];
  const isNewLite =
    planId === plan_ids["lite"] || planId === plan_ids["liteWabaAdv"];
  const isLite = planId === old_plan_ids["lite"];
  const isNewPro =
    planId === plan_ids["pro"] || planId === plan_ids["proWabaAdv"];
  const isPro =
    planId === old_plan_ids["pro"] || planId === old_plan_ids["wabaAdv"];
  const isNewV2 = isBiz || isLite || isPro;
  const isNewV3 = isNewBiz || isNewLite || isNewPro;

  const isNonStandardPlan =
    !isBiz && !isNewBiz && !isLite && !isNewLite && !isPro && !isNewPro;

  return {
    isBiz,
    isLite,
    isPro,
    isNewV2,
    isNewV3,
    isOld: !isNewV2 && !isNewV3,
    isNewBiz,
    isNewLite,
    isNewPro,
    isNonStandardPlan,
    isBizOldOrNew: isBiz || isNewBiz,
    isLiteOldOrNew: isLite || isNewLite,
    isProOldOrNew: isPro || isNewPro,
    isBizLiteOrProOldOrNew:
      isBiz || isNewBiz || isLite || isNewLite || isPro || isNewPro,
  };
};

export const almost_full_limit = 0.8;

export const businessPlanFeatures = [
  "30 days Call Recording",
  "Business Caller Tune",
  "Reports",
];

export const basicCRMFeatures = [
  "1 lead source integration",
  "Unlimited leads, 500 storage limit",
  "Fresh lead alert, tasks, reminders",
  "Web dashboard (owners only)",
  "Data protection & number masking",
];

export const advancedCRMFeatures = [
  "Multiple lead source integration",
  "Unlimited leads, 10000 storage limit",
  "Agent Assignment",
  "Web dashboard (all users)",
  "Unlimited tags, lead stages, message templates",
  "Advanced Reports",
  "Data protection & number masking",
];

export const enterpriseFeatures = [
  "Manage multiple numbers",
  "Webhooks & Integrations",
  "Custom lead integrations",
  "Automations",
  "Initiate call from web dashboard",
  "AI Call summary coming soon",
];

export const sharedDemoOrgs =
  process.env.REACT_APP_API_HOST ===
  "https://prod-api.superfone.co.in/superfone"
    ? [
        150877, 150878, 150879, 150880, 150881, 150882, 150883, 150884, 150885,
        150886, 150887, 150888, 150889, 150890, 150891, 150892, 150893, 150894,
        150895, 150896,
      ]
    : [
        115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128,
        129, 130, 131, 132, 133, 134,
      ];
