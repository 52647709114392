import { Suspense, lazy } from "react";
import {
  useCancelOrderMutation,
  useDeleteOrgMutation,
  useLazyLoginOrgQuery,
  useLazyGetPendingOrderQuery,
  useLazyUsersSyncQuery,
  useLazyGetOrgsQuery,
  useLazyGetSummariesQuery,
  useGetOrgsQuery,
  useGetSummariesQuery,
} from "../../store/api/api";
import { useLocation } from "react-router-dom";
import { AppRoutesObject } from "../../pages/app-routes.component";
import { useSelector } from "react-redux";
import {
  selectPurchaseOrgId,
  selectRenewOrgId,
  selectTopUpOrgId,
  selectUpgradeOrgId,
} from "../../store/global/global.selector";
import { StyledModal } from "./global-modals.styles";
import { useDispatch } from "react-redux";
import {
  setPurchaseOrgId,
  setRenewOrgId,
  setTopUpOrgId,
  setUpgradeOrgId,
} from "../../store/global/global.reducer";
import { Modal, message } from "antd";
import { isDeletableOrg } from "../../store/api/api.selector";
import { useEffectOnce } from "../../hooks/use-effect-once";
import { getPlanLevel } from "../purchase/constants";

const PurchaseModal = lazy(
  () => import("../purchase/purchase-modal.component")
);
const RenewModal = lazy(() => import("../renew/renew-modal.component"));
const TopupModal = lazy(() => import("../topup/topup-modal.component"));
const TopupNewModal = lazy(() => import("../topup/topup-modal-new.component"));
const EmailModal = lazy(() => import("../email-modal/email-modal.component"));
const UpgradeModal = lazy(() => import("../upgrade/upgrade-modal.component"));

export const GlobalModals = () => {
  const location = useLocation();
  const [usersSyncApi, { data: usersSync }] = useLazyUsersSyncQuery();
  const topUpOrgId = useSelector(selectTopUpOrgId);
  const purchaseOrgId = useSelector(selectPurchaseOrgId);
  const renewOrgId = useSelector(selectRenewOrgId);
  const upgradeOrgId = useSelector(selectUpgradeOrgId);
  const dispatch = useDispatch();
  const [cancelOrder] = useCancelOrderMutation();
  const [loginOrg] = useLazyLoginOrgQuery();
  const [getPendingOrder] = useLazyGetPendingOrderQuery();
  const [deleteOrg] = useDeleteOrgMutation();
  const [getOrgs] = useLazyGetOrgsQuery();
  const [getSummaries] = useLazyGetSummariesQuery();
  const orgsQuery = useGetOrgsQuery();
  const renewOrg = orgsQuery.data?.find((o) => o.id === renewOrgId);
  useEffectOnce(() => {
    const handlePendingOrder = async () => {
      const [usersSyncResponse, orgsResponse, summariesResponse] =
        await Promise.all([
          usersSyncApi(undefined, true),
          getOrgs(undefined, true),
          getSummaries(undefined, true),
        ]);
      const orgs = orgsResponse?.data || [];
      const summaries = summariesResponse?.data || [];
      const defaultOrgId = usersSyncResponse?.data?.org?.id || 0;

      if (defaultOrgId) {
        const loginOrgResponse = await loginOrg(defaultOrgId);
        if (loginOrgResponse?.data?.userData?.id) {
          const pendingOrderResponse = await getPendingOrder();
          const pendingOrder = pendingOrderResponse?.data;
          if (pendingOrder?.id) {
            const orderType = pendingOrder?.details?.order_type;
            const orderId = pendingOrder?.id || 0;
            const orgId = pendingOrder?.org_id || 0;
            const org = orgs?.find((o) => o.id === orgId);
            if (org) {
              await loginOrg(orgId);
              if (orderType === "PURCHASE") {
                dispatch(setPurchaseOrgId(orgId));
              } else if (orderType === "TOPUP") {
                dispatch(setTopUpOrgId(orgId));
              } else if (orderType === "RENEW") {
                dispatch(setRenewOrgId(orgId));
              } else if (orderType === "UPGRADE") {
                dispatch(setUpgradeOrgId(orgId));
              }
            } else {
              cancelOrder({
                order_id: orderId,
                status: "cancelled",
              });
            }
          } else {
            const deletableOrgs = orgs.filter((o) => {
              const summary = summaries?.find((s) => s.org_id === o.id);
              return isDeletableOrg({
                has_subscription: !!summary?.subscription,
                has_superfone_number: !!o?.superfone_number,
                is_active: !!summary?.is_active,
                is_trial: !!summary?.is_trial,
                is_trial_active: !!summary?.is_trial_active,
              });
            });
            await Promise.all(deletableOrgs.map((o) => deleteOrg(o.id)));
            await Promise.all([getOrgs(), getSummaries()]);
          }
        }
      }
    };
    handlePendingOrder();
  });

  const onCancelTopupPayment = () => {
    Modal.confirm({
      title: "Cancel payment",
      content: "Are you sure you want to cancel this payment?",
      onOk: async () => {
        const pendingOrderResponse = await getPendingOrder();
        if ("data" in pendingOrderResponse) {
          const pendingOrderId = pendingOrderResponse?.data?.id;
          if (pendingOrderId) {
            const orgId = pendingOrderResponse?.data?.org_id || 0;
            await loginOrg(orgId);
            const cancelOrderResponse = await cancelOrder({
              order_id: pendingOrderId,
              status: "cancelled",
            });
            if ("data" in cancelOrderResponse) {
              message.success("Order cancelled");
              dispatch(setTopUpOrgId(0));
            } else {
              message.error("Failed to cancel order");
            }
          } else {
            dispatch(setTopUpOrgId(0));
          }
        }
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  const deleteTeamAndClosePurchaseModal = async () => {
    if (purchaseOrgId) {
      await deleteOrg(purchaseOrgId);
      await Promise.all([getOrgs(), getSummaries()]);
    }

    dispatch(setPurchaseOrgId(0));
  };

  const onCancelPurchasePayment = () => {
    Modal.confirm({
      title: "Cancel payment",
      content: "Are you sure you want to cancel this payment?",
      onOk: async () => {
        const pendingOrder = await getPendingOrder();
        const pendingOrderId = pendingOrder?.data?.id || 0;
        if (pendingOrderId) {
          const orgId = pendingOrder?.data?.org_id || 0;
          await loginOrg(orgId);
          const cancelOrderResponse = await cancelOrder({
            order_id: pendingOrderId,
            status: "cancelled",
          });
          if ("data" in cancelOrderResponse) {
            message.success("Order cancelled");
            await deleteTeamAndClosePurchaseModal();
          } else {
            message.error("Failed to cancel order");
          }
        } else {
          await deleteTeamAndClosePurchaseModal();
        }
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  const onCancelRechargePayment = () => {
    Modal.confirm({
      title: "Cancel payment",
      content: "Are you sure you want to cancel this payment?",
      onOk: async () => {
        const pendingOrder = await getPendingOrder();
        const pendingOrderId = pendingOrder?.data?.id || 0;
        if (pendingOrderId) {
          const orgId = pendingOrder?.data?.org_id || 0;
          await loginOrg(orgId);
          const cancelOrderResponse = await cancelOrder({
            order_id: pendingOrderId,
            status: "cancelled",
          });
          if ("data" in cancelOrderResponse) {
            message.success("Order cancelled");
            dispatch(setRenewOrgId(0));
          } else {
            message.error("Failed to cancel order");
          }
        } else {
          dispatch(setRenewOrgId(0));
        }
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  const onCancelUpgradePayment = () => {
    Modal.confirm({
      title: "Cancel payment",
      content: "Are you sure you want to cancel this payment?",
      onOk: async () => {
        const pendingOrder = await getPendingOrder();
        const pendingOrderId = pendingOrder?.data?.id || 0;
        if (pendingOrderId) {
          const orgId = pendingOrder?.data?.org_id || 0;
          await loginOrg(orgId);
          const cancelOrderResponse = await cancelOrder({
            order_id: pendingOrderId,
            status: "cancelled",
          });
          if ("data" in cancelOrderResponse) {
            message.success("Order cancelled");
            dispatch(setUpgradeOrgId(0));
          } else {
            message.error("Failed to cancel order");
          }
        } else {
          dispatch(setUpgradeOrgId(0));
        }
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  const summaries = useGetSummariesQuery();

  const topUpSummary = summaries?.data?.find((s) => s.org_id === topUpOrgId);
  const { isNewV3 } = getPlanLevel(topUpSummary?.subscription?.plan_id || 0);

  return (
    <>
      {usersSync?.current_subscription?.subscription &&
      usersSync?.user?.tnc_accepted &&
      AppRoutesObject.GetStarted.url !== location.pathname ? (
        <Suspense>
          <EmailModal />
        </Suspense>
      ) : null}
      <StyledModal
        onCancel={onCancelTopupPayment}
        open={!!topUpOrgId}
        footer={null}
        width="400px"
        centered
        title={"Add Pulse Minutes"}
        destroyOnClose
      >
        {topUpOrgId ? (
          <Suspense>
            {isNewV3 ? (
              <TopupNewModal
                onCancel={onCancelTopupPayment}
                orgId={topUpOrgId}
              />
            ) : (
              <TopupModal onCancel={onCancelTopupPayment} orgId={topUpOrgId} />
            )}
          </Suspense>
        ) : null}
      </StyledModal>
      <StyledModal
        onCancel={onCancelPurchasePayment}
        open={!!purchaseOrgId}
        footer={null}
        width="400px"
        centered
        title={"Purchase Superfone Number"}
        destroyOnClose
      >
        {purchaseOrgId ? (
          <Suspense>
            <PurchaseModal orgId={purchaseOrgId} />
          </Suspense>
        ) : null}
      </StyledModal>
      <StyledModal
        onCancel={onCancelRechargePayment}
        open={!!renewOrgId}
        footer={null}
        width="400px"
        centered
        title={`Recharge ${renewOrg?.name || ""}`}
        destroyOnClose
      >
        {renewOrgId ? (
          <Suspense>
            <RenewModal orgId={renewOrgId} />
          </Suspense>
        ) : null}
      </StyledModal>
      <StyledModal
        onCancel={onCancelUpgradePayment}
        open={!!upgradeOrgId}
        footer={null}
        width="400px"
        centered
        title={"Upgrade"}
        destroyOnClose
      >
        {upgradeOrgId ? (
          <Suspense>
            <UpgradeModal orgId={upgradeOrgId} />
          </Suspense>
        ) : null}
      </StyledModal>
    </>
  );
};
