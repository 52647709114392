import { RootState } from "../store";
import { GetMemberResponse } from "./types/get-member.types";
import { Summary } from "./types/get-summaries.types";
import {
  UsersSyncResponse,
  CurrentSubscription,
} from "./types/users-sync.types";
import { api } from "./api";
import {
  DISPLAY_DATE_FORMAT,
  newTeamName,
  OrgStatus,
} from "../../utils/constants";
import {
  capitalize,
  formatDate,
  Owner,
  pendingActionsTypes,
} from "../../utils/utils";
import dayjs from "dayjs";
import { OrgSubscription } from "./types/get-org-subscriptions.types";
import { createSelector } from "reselect";
import relativeTime from "dayjs/plugin/relativeTime";
import { Member } from "./types/member.types";
import { Presence } from "./types/get-presence.types";
import {
  almost_full_limit,
  getPlanLevel,
} from "../../components/purchase/constants";
import { LeadsCount } from "./types/get-all-leads-count.types";

dayjs.extend(relativeTime);

export const selectStaffs = (state: RootState) => {
  const staffs = api.endpoints.getAllMembers.select()(state).data || [];
  return staffs;
};

export const selectOwner = createSelector([selectStaffs], (staffs) =>
  staffs.find((staff) => {
    return staff?.role_name === Owner;
  })
);

export const selectOwnerFirstName = createSelector([selectOwner], (owner) => {
  return owner?.first_name || "";
});

export const selectOwnerLastName = createSelector([selectOwner], (owner) => {
  return owner?.last_name || "";
});

export const selectOwnerName = createSelector(
  [selectOwnerFirstName, selectOwnerLastName],
  (firstName, lastName) => {
    return `${firstName} ${lastName}`;
  }
);

export const selectPresences = (state: RootState) => {
  const orgs = api.endpoints.getOrgs.select()(state).data || [];
  return (
    api.endpoints.getPresenceOfMembers.select(orgs.map((org) => org.id))(state)
      .data || []
  );
};

export const selectUniqueStaffs = createSelector([selectStaffs], (staffs) => {
  const groups: Member[][] = [];
  const staffIds = staffs.map((staff) => staff.id);
  const uniqueIds = new Set(staffIds);
  uniqueIds.forEach((id) => {
    //@ts-ignore
    groups.push(staffs.filter((staff) => staff.id === id));
  });
  const uniqueStaffs = groups.map((group) => {
    const uniqueOrgNames = new Set(
      group.map((staff) => staff.org_name || "").filter((e) => !!e)
    );
    const uniqueSuperfoneNumbers = new Set(
      group.map((staff) => staff.org_superfone_number || "").filter((e) => !!e)
    );
    const unqiueRoles = new Set(
      group.map((staff) => staff.role_name || "").filter((e) => !!e)
    );
    return {
      ...group[0],
      org_name: Array.from(uniqueOrgNames).join(", "),
      org_superfone_number: Array.from(uniqueSuperfoneNumbers).join(", "),
      role_name: Array.from(unqiueRoles).join(", "),
    } as Member;
  });
  return uniqueStaffs;
});

export const selectStaffTableRows = createSelector(
  [selectUniqueStaffs, selectPresences],
  (staffs, presences) => {
    return transformToStaffData(staffs, presences);
  }
);

export type StaffRow = ReturnType<typeof selectStaffTableRows>[number];

type StaffOption = {
  firstName: string;
  lastName: string;
  label: string;
  value: number;
  orgId: number;
};

export const selectUniqueMembers = (members?: Member[]) => {
  const memberIds = members?.map((member) => member.id) || [];
  const unqiueMemberIds = new Set(memberIds);
  return Array.from(unqiueMemberIds)
    .map((memberId) => {
      return members?.find((member) => member.id === memberId)!;
    })
    .filter((e) => !!e);
};

export const selectUniqueStaffOptions = (staffOptions: StaffOption[]) => {
  const staffIds = (staffOptions || [])?.map(
    (staffOption) => staffOption.value
  );
  const unqiueStaffIds = new Set(staffIds);
  return Array.from(unqiueStaffIds)
    .map((staffId) => {
      return staffOptions.find((staff) => staff.value === staffId)!;
    })
    .filter((e) => !!e);
};

const transformToStaffData = (data: Member[], presences: Presence[]) => {
  return data.map((staff, index) => {
    const presence = presences?.find((p) => {
      return p?.username === staff.user_name;
    });
    const connected_at = presence?.connected_at
      ? dayjs(presence.connected_at)
      : null;
    const lastConnected = connected_at?.fromNow() || "";
    const online = presence?.status === "OK";
    const dnd = presence?.status === "DND";
    const connection = dnd ? "DND" : online ? "Online" : lastConnected;
    return {
      snno: index + 1,
      orgId: staff.org_id,
      id: staff.id,
      userId: staff.id,
      name: `${staff.first_name || ""} ${staff.last_name || ""}`,
      registeredNumber: staff.phone,
      teamName: staff.org_name,
      superfoneNumber: staff.org_superfone_number,
      teamRole: staff.role_name,
      status: capitalize(staff.state),
      connection,
      online,
      dnd,
    };
  });
};

export const selectPermissionsResponse = (state: RootState) => {
  return api.endpoints.getPermissions.select()(state).data;
};

export const selectPermissionsSection = createSelector(
  [selectPermissionsResponse],
  (permissionsResponse) => {
    return permissionsResponse?.permission_section;
  }
);

export const TeamManagement = "TEAM MANAGEMENT";

export const selectTeamManagementPermissions = createSelector(
  [selectPermissionsSection],
  (permission_section) => {
    return (
      permission_section?.find(
        (permissions) => permissions.title === TeamManagement
      )?.permissions || []
    );
  }
);

export const selectInviteStaffPermission = createSelector(
  [selectTeamManagementPermissions],
  (permissions) =>
    permissions.find((permission) => permission.slug === "invite-user")
);

export const selectRemoveUserPermission = createSelector(
  [selectTeamManagementPermissions],
  (permissions) =>
    permissions.find((permission) => permission.slug === "remove-user")
);

export const selectRemoveOwnerPermission = createSelector(
  [selectTeamManagementPermissions],
  (permissions) =>
    permissions.find((permission) => permission.slug === "remove-owner")
);

export const selectIsDemoUser = (subscription?: CurrentSubscription) => {
  const isDemoUser =
    subscription?.is_active === false &&
    subscription?.is_trial === false &&
    subscription?.is_trial_active === false;
  return isDemoUser;
};

export const selectIsTrialUser = (subscription?: CurrentSubscription) => {
  const isTrialUser =
    subscription?.is_trial === true && subscription?.is_active === false;
  return isTrialUser;
};

export const selectIsUnpaidUser = (subscription?: CurrentSubscription) => {
  return selectIsTrialUser(subscription) || selectIsDemoUser(subscription);
};

export const selectUserPaidStatus = (user?: UsersSyncResponse) => {
  const isDemoUser = selectIsDemoUser(user?.current_subscription);
  const isTrialUser = selectIsTrialUser(user?.current_subscription);
  return {
    isDemoUser,
    isTrialUser,
    isUnpaidUser: isDemoUser || isTrialUser,
    user,
  };
};

export const selectIsFreeTier = (summaries: Summary[]) => {
  const activePlans = summaries.filter((s) => {
    const isActive = s.is_active;
    const isNotTrial = !s.is_trial;
    return isActive && isNotTrial;
  });

  if (activePlans?.length === 1) {
    return {
      summaries,
      isFreeTier: activePlans?.[0]?.subscription?.plan?.slug === "free-tier",
    };
  }

  return {
    summaries,
    isFreeTier: false,
  };
};

export const selectActivePlans = (summaries: Summary[]) =>
  summaries?.filter((s) => s?.is_active && s?.is_trial === false) || [];

export const selectIsBizPhonePlan = (summaries: Summary[]) => {
  const plans = selectActivePlans(summaries);
  const isBizPhonePlan = plans.every((s) => {
    return getPlanLevel(s?.subscription?.plan?.id).isBizOldOrNew;
  });

  return { isBizPhonePlan: !!plans.length && isBizPhonePlan };
};

export const selectIsAllInOneLitePlan = (summaries: Summary[]) => {
  const plans = selectActivePlans(summaries);
  const isAllInOneLitePlan = plans.every((s) => {
    return getPlanLevel(s?.subscription?.plan?.id).isLiteOldOrNew;
  });

  return { isAllInOneLitePlan: !!plans.length && isAllInOneLitePlan };
};

export const selectIsBizOrLitePlan = (summaries: Summary[]) => {
  const plans = selectActivePlans(summaries);
  const isBizOrLitePlan = plans.every((s) => {
    const planId = s?.subscription?.plan?.id || 0;
    const { isBizOldOrNew, isLiteOldOrNew } = getPlanLevel(planId);
    return isBizOldOrNew || isLiteOldOrNew;
  });

  return { isBizOrLitePlan: !!plans.length && isBizOrLitePlan };
};

export const selectIsAllInOneProPlan = (summaries: Summary[]) => {
  const plans = selectActivePlans(summaries);
  const isAllInOneProPlan = plans.every((s) => {
    return getPlanLevel(s?.subscription?.plan?.id).isProOldOrNew;
  });

  return { isAllInOneProPlan: !!plans.length && isAllInOneProPlan };
};

export const selectSummariesWithFlags = (summaries: Summary[]) => {
  const { isFreeTier } = selectIsFreeTier(summaries || []);
  const { isBizPhonePlan } = selectIsBizPhonePlan(summaries || []);
  const { isAllInOneLitePlan } = selectIsAllInOneLitePlan(summaries || []);
  const { isAllInOneProPlan } = selectIsAllInOneProPlan(summaries || []);
  const { isBizOrLitePlan } = selectIsBizOrLitePlan(summaries || []);

  return {
    isFreeTier,
    isBizPhonePlan,
    isAllInOneLitePlan,
    isBizOrLitePlan,
    isAllInOneProPlan,
    isNewPlan: isBizPhonePlan || isAllInOneLitePlan || isAllInOneProPlan,
    summaries,
  };
};

export const selectIsNumberMaskingEnabled = (
  data?: GetMemberResponse,
  isFetching?: boolean,
  isLoading?: boolean
) => {
  return {
    data,
    isNumberMaskingEnabled: data?.role_permissions?.some(
      (p) => p?.permissions?.includes("number-masking") || false
    ),
    isFetching: isFetching || false,
    isLoading: isLoading || false,
  };
};

const selectOrgs = (state: RootState) =>
  api.endpoints.getOrgs.select()(state).data || [];

export const selectOrgsWithStatus = createSelector(
  [
    selectOrgs,
    (state) => api.endpoints.getSummaries.select()(state).data || [],
  ],
  (orgs, summaries) => {
    return (
      orgs?.map((org) => {
        const summary = summaries.find((s) => s.org_id === org.id);
        const {
          is_active: isActive,
          is_expiring_soon: isExpiringSoon,
          is_trial: isTrial,
          is_trial_active: isTrialActive,
        } = summary || {};

        return {
          ...org,
          status: isTrial
            ? isTrialActive
              ? OrgStatus.Trial
              : OrgStatus.TrialExpired
            : isActive && !isTrial
            ? OrgStatus.Active
            : isExpiringSoon
            ? OrgStatus.ExpiringSoon
            : OrgStatus.Expired,
          is_trial: summary?.is_trial,
          is_trial_active: summary?.is_trial_active,
          is_active: summary?.is_active && !summary?.is_trial,
          subscription: summary?.subscription,
          crm: {
            paid: summary?.is_paid_crm_active,
            trial: summary?.is_trial_crm_active,
          },
        };
      }) || []
    );
  }
);

export const selectUserSyncData = (state: RootState) =>
  api.endpoints.usersSync.select()(state).data;

export const selectUser = createSelector(
  [selectUserSyncData],
  (user) => user?.user
);

export const selectTopStripData = createSelector(
  [selectOrgsWithStatus],
  (orgs) => {
    const expired = orgs.find((org) => org.status === OrgStatus.Expired);
    const trialExpired = orgs.find(
      (org) => org.status === OrgStatus.TrialExpired
    );
    const crmTrial = orgs.find((o) => !!o.crm.trial && !o.crm.paid);
    const expiringSoon = orgs.find(
      (org) => org.status === OrgStatus.ExpiringSoon
    );

    if (expired) {
      return {
        org: expired,
        code: "Expired",
        bgColor: "rgba(229, 26, 35, 0.57)",
        text: "One or more of your Superfone subscriptions have expired. Click here to renew",
        strong: "expired. Click here to renew",
      };
    } else if (trialExpired) {
      return {
        org: trialExpired,
        code: "Trial Expired",
        bgColor: "rgba(229, 26, 35, 0.57)",
        text: "Your Superfone trial(s) have expired. Click here to start your subscription",
        strong: "expired. Click here to start your subscription",
      };
    } else if (crmTrial) {
      return {
        org: crmTrial,
        code: "CRM Trial",
        bgColor: "rgba(237, 211, 209, 1)",
        text: `CRM Trial Enabled for your Team ${
          crmTrial?.name || ""
        }. Trial period ends on ${
          crmTrial?.crm_trial?.valid_till
            ? dayjs(crmTrial.crm_trial.valid_till).format(DISPLAY_DATE_FORMAT)
            : ""
        }. `,
        strong: "Book Setup",
      };
    } else if (expiringSoon) {
      return {
        org: expiringSoon,
        code: "Expiring Soon",
        bgColor: "rgba(253, 253, 150, 1)",
        text: "One or more of your Superfone subscriptions are expiring soon. Click here to renew",
        strong: "expiring soon. Click here to renew",
      };
    } else
      return { org: null, code: "Active", bgColor: "rgba(22, 157, 0, 0.57)" };
  }
);

export const selectOrgWithStatusRows = createSelector(
  [selectOrgsWithStatus],
  (orgsWithStatus) => {
    return transformToTableData(orgsWithStatus);
  }
);

type OrgsWithStatus = ReturnType<typeof selectOrgsWithStatus>;

export type OrgWithStatusRow = ReturnType<
  typeof selectOrgWithStatusRows
>[number];

export const selectOrgsCount = createSelector(
  [selectOrgs],
  (orgs) => orgs.length || 0
);

export const selectOrgSubscriptions = (state: RootState) =>
  api.endpoints.getOrgSubscriptions.select(state.global.selectedOrgId)(state)
    .data || [];

export const selectOrgSubscriptionRows = createSelector(
  [selectOrgSubscriptions],
  (orgSubscriptions) => {
    return transformToSubscriptionTableRows(orgSubscriptions);
  }
);

export type OrgSubscriptionRow = ReturnType<
  typeof selectOrgSubscriptionRows
>[number];

const transformToTableData = (data: OrgsWithStatus) => {
  return data.map((data, index) => ({
    snno: index + 1,
    id: data.id,
    teamId: data.id,
    teamName: data.name,
    superfoneNumber: data.superfone_number,
    status: data.status,
    teamStaffsCount: data.users.length || 0,
    isTrial: data.is_trial,
    is_active: data.is_active && !data.is_trial,
    is_trial_active: data.is_trial_active,
    subscription: data.subscription,
    is_trial: data.is_trial,
  }));
};

const transformToSubscriptionTableRows = (data: OrgSubscription[]) => {
  return data.map((o, index) => ({
    snno: index + 1,
    id: o.id,
    subscriptionId: o.id,
    title: o.title,
    status: capitalize(o.status),
    startedOn: formatDate(o.createdAt),
    validTill: formatDate(o.valid_till),
    payment: o.payment,
  }));
};

export const isDeletableOrg = (params: {
  has_superfone_number: boolean;
  is_trial: boolean;
  is_active: boolean;
  is_trial_active: boolean;
  has_subscription: boolean;
}) => {
  return (
    params.has_superfone_number === false &&
    params.is_trial === false &&
    params.is_active === false &&
    params.is_trial_active === false &&
    params.has_subscription === false
  );
};

export const selectNewOrgs = createSelector([selectOrgsWithStatus], (orgs) => {
  const mainOrgId = Math.min(...orgs.map((org) => org.id));
  const newOrgsWith2PendingActions = orgs
    .filter(
      (org) =>
        org.name === newTeamName &&
        org.users.length <= 1 &&
        org.is_active === true
    )
    .map((org) => ({
      ...org,
      actions: [
        pendingActionsTypes.changeTeamName,
        pendingActionsTypes.addStaff,
      ],
    }));
  const newOrgsWithSystemName = orgs
    .filter((org) => {
      const alreadyExists = newOrgsWith2PendingActions.find(
        (newOrg) => newOrg.id === org.id
      );
      if (alreadyExists) {
        return false;
      }
      return true;
    })
    .filter((org) => org.name === newTeamName && org.is_active === true)
    .map((org) => ({
      ...org,
      actions: [pendingActionsTypes.changeTeamName],
    }));

  const newOrgsWith1Staff = orgs
    .filter((org) => {
      const alreadyExists = newOrgsWith2PendingActions.find(
        (newOrg) => newOrg.id === org.id
      );
      if (alreadyExists) {
        return false;
      }
      return true;
    })
    .filter(
      (org) =>
        org.users.length <= 1 && org.is_active === true && org.id !== mainOrgId
    )
    .map((org) => ({
      ...org,
      actions: [pendingActionsTypes.addStaff],
    }));

  return [
    ...newOrgsWith2PendingActions,
    ...newOrgsWithSystemName,
    ...newOrgsWith1Staff,
  ];
});

export const showPendingActionAlert = createSelector(
  [selectNewOrgs],
  (orgs) => orgs.length > 0
);

export const selectGetAllOrgsSettings = (state: RootState) => {
  return api.endpoints.getAllOrgsSettings.select()(state)?.data || [];
};

export const selectAllOrgsWithCallerTune = createSelector(
  [selectGetAllOrgsSettings, selectOrgs],
  (orgSettings, orgs) => {
    const filteredOrgs =
      orgSettings?.filter(
        (settings) => settings?.[0]?.caller_tune === "custom"
      ) || [];
    return filteredOrgs.map((settings) => {
      const thisOrg = orgs.find((o) => o.id === settings?.[0]?.org_id);
      return {
        orgDetails: thisOrg,
        settings: settings,
      };
    });
  }
);

// export const selectFBPages = (state: RootState) =>
//   api.endpoints?.getFaceBookPages.select(state.org.selectedOrgId)(state)
//     .data || [];
// export const selectFBPagesSelector = createSelector(
//   [selectFBPages],
//   (pages) => {
//     return pages.map((o: any, index: number) => ({
//       snno: index + 1,
//       id: o.id,
//       // subscriptionId: o.id,
//       // title: o.title,
//       // status: capitalize(o.status),
//       // startedOn: formatDate(o.createdAt),
//       // validTill: formatDate(o.valid_till),
//       // payment: o.payment,
//     }));
//   }
// );

export const selectOrgSubscriptionsSummaries = (state: RootState) =>
  api.endpoints.getSummaries.select()(state).data || [];

export const selectOrgIdToPayFor = createSelector(
  [selectOrgSubscriptionsSummaries],
  (summaries) => {
    const summary = summaries.find((s) => s?.is_trial === true);
    return summary?.org_id || 0;
  }
);

export const selectIsCRMActive = (
  summaries?: Summary[],
  userOrgIds?: number[]
) => {
  return (
    summaries
      ?.filter?.((summary) => userOrgIds?.includes(summary.org_id))
      ?.some((summary) => {
        return summary?.is_paid_crm_active || summary?.is_trial_crm_active;
      }) || false
  );
};

export const selectLeadCountsWithLimits = (params: {
  leadCounts: LeadsCount[];
  summaries: Summary[];
}) => {
  const { leadCounts, summaries } = params;
  return {
    data:
      leadCounts?.map((c) => {
        const { count, org_id } = c;
        const summary = summaries?.find((s) => s.org_id === org_id);
        const limit =
          Number(summary?.subscription?.features?.["max-lead-storage"]) || 0;
        const percentage = count / (limit || 1);
        const full = !!limit && percentage >= 1;
        const almost_full = !!limit && !full && percentage >= almost_full_limit;
        const normal = !full && !almost_full;
        const maxTagsCount =
          Number(
            summary?.subscription?.features?.["max-lead-labels-storage"]
          ) || 0;
        const maxLeadStageCount =
          Number(summary?.subscription?.features?.["max-lead-stage-storage"]) ||
          0;

        return {
          count: count || 0,
          org_id: org_id || 0,
          percentage,
          full,
          almost_full,
          normal,
          limit,
          maxTagsCount,
          maxLeadStageCount,
        };
      }) || [],
  };
};
