export const convertToSearchParams = (obj: { [x: string]: any }) => {
  if (obj) {
    const entries = Object.entries(obj);
    const query = entries
      .map((entry) => {
        if (entry[1] !== "" && entry[1] !== undefined && entry[1] !== null) {
          return `${String(entry[0])}=${String(entry[1])}`;
        }
        return null;
      })
      .filter((e) => !!e)
      .join("&");
    return query ? `?${query}` : "";
  }
  return "";
};
